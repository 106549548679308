<template>
    <div v-if="userData">
        <button @click="languageDropdownIsOpen = !languageDropdownIsOpen" class="msp-channel-selector-flag-button">
            <template v-if="countryId">
                <img :src="'/img/flag/' + countryId + '.svg'" alt="">
            </template>

            <template v-else>
                <img :src="'/img/flag/' + (userData.channels.length ? userData.channels[0].configuration.country_id : 'undefined') + '.svg'" alt="" style="max-width: 24px">
            </template>
        </button>

        <div v-if="languageDropdownIsOpen" class="msp-channel-selector-dropdown">
            <button  v-for="channel in userData.channels" :key="channel.id" @click="selectedCountryId(channel.configuration.country_id, channel)" class="msp-channel-selector-dropdown-button">
                <img :src="'/img/flag/' + channel.configuration.country_id + '.svg'" alt="" width="20">
                <span>{{ channel.name }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            countryId: null,
            languageDropdownIsOpen: false,
            currentChannel: null,
        }
    },
    created() {
        const currentChannel = localStorage.getItem('currentChannel');
        if (undefined !== currentChannel && 'undefined' !== currentChannel) {
            this.currentChannel = JSON.parse(localStorage.getItem('currentChannel'))

            if (this.currentChannel) {
                this.countryId = this.currentChannel.configuration.country_id
            }
        }

    },
    methods: {
        selectedCountryId(countryId, channel) {
            this.countryId = countryId
            this.languageDropdownIsOpen = false

            this.emitter.emit('filter-by-channel', channel)
        }
    }
}
</script>
