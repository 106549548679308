<template>
    <header class="msp-header">
        <nav class="msp-nav msp-container-large">
            <a href="#" title="Looxis">
                <img src="/img/logo.svg" alt="Looxis" class="msp-logo">
            </a>

            <div class="msp-nav">
                <ChannelSelector :user-data="userData" class="msp-channel-selector-container"></ChannelSelector>

                <p v-if="userData" class="msp-user"><b>Howdy,</b> {{ userData.user.name }}</p>
            </div>
        </nav>
    </header>
</template>

<script>
import ChannelSelector from "../components/ChannelSelector.vue"

export default {
    components: {ChannelSelector},
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
}
</script>
