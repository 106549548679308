<template>
    <div>
        <div v-for="(cartItem, index) in cartItems" :key="cartItem.sku" :class="{ 'msp-card-disabled' : !isCartItemAvailable(cartItem) }" class="msp-card-container">
            <div v-if="!isCartItemAvailable(cartItem)" class="msp-card-disabled-container">
                <p>Product is unavailable on this channel</p>
            </div>

            <div class="msp-summary-product-container">
                <button @click="removeProduct(cartItem)" class="msp-card-icon-trash" title="Remove product">
                    <img src="/img/trash.svg" alt="Trash can">
                </button>

                <img :src="cartItem.img ? cartItem.img : getProductImage(cartItem)" :alt="cartItem.img" class="msp-summary-product-image">

                <div class="msp-summary-product-content">
                    <p>{{ cartItem.name }}</p>

                    <div class="msp-summary-product-info msp-color-light">
                        <button v-if="cartItem.customizationKey" @click="openApcLink(cartItem)" title="Customize">
                            {{ (cartItem.customizationKey).substring(0, 10) + '...' }}
                        </button>

                        <p>
                            <span>Unit price: </span>
                            <span>{{ cartItem.price }} &euro;</span>
                        </p>
                    </div>

                    <div class="msp-summary-product-footer">
                        <QuantityCalculator
                            :quantity="cartItem.quantity"
                            v-on:update:quantity="updateQuantity(cartItem, $event)"
                        ></QuantityCalculator>

                        <b>Sum: {{ totalPriceWithQuantity(cartItem).toFixed(2) }} &euro;</b>
                    </div>
                </div>
            </div>

            <p v-if="getWarningMessage(cartItem) && !cartItemErrorMessages" v-text="getWarningMessage(cartItem)" class="msp-warning-box"></p>

            <div v-if="cartItemErrorMessages" :class="{ 'msp-danger-box' : cartItemErrorMessages[index].length }">
                {{ cartItemErrorMessages[index].join(', ') }}
            </div>
        </div>
    </div>
</template>

<script>
import QuantityCalculator from "../components/QuantityCalculator.vue"

export default {
    components: {QuantityCalculator: QuantityCalculator},
    props: {
        cartItems: {
            type: Array,
            required: true
        },
        totalPriceWithQuantity: {
            type: Function,
            required: true
        },
        products: {
            type: Array
        },
        cartItemErrorMessages: {
            type: String
        }
    },
    created() {
        this.emitter.on('check-product-item-availability', () => {
            this.isCartItemAvailable()
        })

        this.isCartItemAvailable()
    },
    mounted() {
        return this.cartItems.map((cartItem) => {
            this.syncCartItem(cartItem)

            return cartItem
        })
    },
    methods: {
        syncCartItem(cartItem) {
            const product = this.products.find((product) => product.sku === cartItem.sku)

            // If the product is found, update all properties in cartItem with the corresponding properties from product
            if (product) {
                Object.keys(product).forEach((key) => {
                    cartItem[key] = product[key]
                })

                cartItem.total_orderable = this.totalOrderable(cartItem)
            }

            return cartItem
        },
        getWarningMessage(cartItem) {
            if (cartItem.stock > 0 && cartItem.quantity > cartItem.stock) {
                let backOrderQuantity = cartItem.quantity - cartItem.stock
                return `There are only ${cartItem.stock} units left on stock. Shipping of the extra ${backOrderQuantity} units may take longer.`
            }

            if (cartItem.stock <= 0 && cartItem.backorder === null) {
                return 'This product is out of stock, but can be ordered. Be aware that shipping might take longer.'
            }

            if (cartItem.stock <= 0 && cartItem.backorder > 0) {
                let orderable = cartItem.stock + cartItem.backorder
                return `This product is out of stock, but ${orderable} items can be preordered. Be aware that shipping of those might take longer.`
            }
        },
        updateQuantity(cartItem, newQuantity) {
            if (cartItem.total_orderable < newQuantity) {
                alert(`Can not order more than ${cartItem.total_orderable} due to insufficient inventory`)

                return
            }

            cartItem.quantity = newQuantity
        },
        openApcLink(cartItem) {
            const apcLink = this.getApcLink(cartItem)
            window.open(apcLink, '_self')
        },
        getApcLink(cartItem) {
            let formData = `product=${cartItem.id}&related_product=&options[5012]=6755&options[5013]=CoYNVn3zhsyQTnJtU63ea0816c3b6d&qty=1&input_marker=1`
            let baseData = encodeURIComponent(
                window.btoa(
                    formData + `&token=rre0jverv0rv88374ht57f&customizationKey=${cartItem.customizationKey}&storeUrl=${window.location.href}&lang=${'en'}`
                )
            )

            return "https://v5.cnfgrtr.com/simple-configuration?data=" + baseData
        },
        removeProduct(cartItem) {
            this.emitter.emit("remove-cart-item", cartItem.sku)
        },
        isCartItemAvailable(cartItem) {
            const storedChannel = localStorage.getItem('currentChannel')
            if (undefined === storedChannel || 'undefined' === storedChannel) {
                return false
            }

            const channel = JSON.parse(localStorage.getItem('currentChannel'))

            if (cartItem && channel) {
                return cartItem.channels.includes(channel.id)
            }
        },
        getProductImage(cartItem) {
            const primaryImage = cartItem.images.find(image => image.is_primary)

            if (primaryImage) {
                return primaryImage.urls.checkout
            } else if (cartItem.images.length > 0) {
                return cartItem.images[0].urls.checkout
            } else {
                return '/img/noImagePlaceholder.webp'
            }
        }
    }
}
</script>
