<template>
    <div class="msp-side-content-container">
        <CartItem
            v-if="!cartItemsLoading"
            :cart-items="cartItems"
            :products="products"
            :cart-item-error-messages="cartItemErrorMessages"
            :total-price-with-quantity="totalPriceWithQuantity"
        ></CartItem>

        <ShippingAddress
            id="shipping-address-title"
            :address-fields="addressFields"
            :form-invalid="formInvalid"
            :postal-code-length="postalCodeLengthInvalid"
            @country-code-selected="updateCountryCode"
        ></ShippingAddress>

        <div v-if="errorMessage" class="msp-danger-box">{{ errorMessage }}</div>

        <ShippingMethod
            :shipping-methods="shippingMethods"
            :selected-shipping-method-index="selectedShippingMethodIndex"
            :add-delivery-method="addDeliveryMethod"
            :shipping-method-error="shippingMethodError"
        ></ShippingMethod>

        <div class="msp-card-container">
            <p class="msp-card-title">Order Summary</p>

            <div v-for="category in priceCategories" :key="category.label" class="msp-summary-price-container">
                <p>{{ category.label }}</p>
                <p v-if="category.type === 'subtotal'">{{ calculateTotalPrice.toFixed(2) }} &euro;</p>
                <p v-if="category.type === 'shippingFee'">{{ calculateSelectedDeliveryPrice }} &euro;</p>
                <p v-if="category.type === 'vat'">{{ ((calculateTotalPrice + calculateSelectedDeliveryPrice) * 0.19).toFixed(2) }} &euro;</p>
                <p v-if="category.type === 'total'">{{ (calculateTotalPrice + calculateSelectedDeliveryPrice).toFixed(2) }} &euro;</p>
            </div>
        </div>

        <div class="msp-card-container">
            <button v-if="shippingMethodError || !shippingMethods.length" class="msp-button msp-button-full msp-button-large msp-button-disabled">Send</button>
            <button v-else @click="submitData" class="msp-button msp-button-full msp-button-large msp-button-primary">Send</button>
        </div>
    </div>
</template>

<script>
import CartItem from "./CartItem.vue"
import ShippingAddress from "../components/ShippingAddress.vue"
import ShippingMethod from "../components/ShippingMethod.vue"
import {BASE_URL, PAYMENT_METHOD_ID} from "../../config"

export default {
    components: {ShippingAddress, ShippingMethod, CartItem},
    props: {
        userApiKey: {
            type: String,
            required: true,
        },
        cartItemsLoading: {
            type: Boolean,
            required: true
        },
        cartItems: {
            type: Array,
            required: true
        },
        products: {
            type: Array
        }
    },
    data() {
        return {
            selectedShippingMethodIndex: 0,
            shippingMethods: [],
            shippingMethodError: false,
            formInvalid: false,
            postalCodeLengthInvalid: false,
            formSuccess: false,
            buttonDisabled: false,
            errorMessage: null,
            cartItemErrorMessages: null,
            priceCategories: [
                { label: 'Subtotal', type: 'subtotal' },
                { label: 'Shipping Fee', type: 'shippingFee' },
                { label: 'Vat', type: 'vat' },
                { label: 'Total', type: 'total' }
            ],
            addressFields: [
                {
                    label: 'First Name',
                    name: 'firstname',
                    required: true,
                },
                {
                    label: 'Last Name',
                    name: 'lastname',
                    required: true,
                },
                {
                    label: 'Email',
                    name: 'email'
                },
                {
                    label: 'Phone',
                    name: 'phone'
                },
                {
                    label: 'Address',
                    name: 'address',
                    required: true,
                },
                {
                    label: 'Company',
                    name: 'company_name',
                },
                {
                    label: 'City',
                    name: 'city',
                    required: true,
                },
                {
                    label: 'Post Code',
                    name: 'postalcode',
                    required: true,
                },
                {
                    label: 'Country',
                    name: 'country',
                    required: true,
                    countries: [
                        { code: 'AT', name: 'Austria' },
                        { code: 'BE', name: 'Belgium' },
                        { code: 'CZ', name: 'Czech Republic' },
                        { code: 'DK', name: 'Denmark' },
                        { code: 'FR', name: 'France' },
                        { code: 'DE', name: 'Germany' },
                        { code: 'IT', name: 'Italy' },
                        { code: 'LU', name: 'Luxembourg' },
                        { code: 'NL', name: 'Netherlands' },
                        { code: 'PL', name: 'Poland' },
                        { code: 'ES', name: 'Spain' },
                        { code: 'SE', name: 'Sweden' },
                    ]
                }
            ],
        }
    },
    mounted() {
        this.emitter.on('check-product-item-availability', () => {
            this.cartItems.forEach(cartItem => {
                this.isCartItemAvailable(cartItem)
            })
        })
    },
    computed: {
        calculateTotalPrice() {
            return this.cartItems.reduce((total, cartItem) => {
                if (!this.cartItemsLoading) {
                    return parseFloat(total + this.totalPriceWithQuantity(cartItem))
                }

                return 0
            }, 0)
        },
        calculateSelectedDeliveryPrice() {
            if (this.shippingMethods.length > 0 && this.shippingMethods[this.selectedShippingMethodIndex].shipping_fee.amount) {
                return this.shippingMethods[this.selectedShippingMethodIndex].shipping_fee.amount
            }

            return 0
        }
    },
    methods: {
        isCartItemAvailable(cartItem) {
            const currentChannel = localStorage.getItem('currentChannel')
            if (undefined === currentChannel || 'undefined' === currentChannel) {
                alert('Choose a Country/Language first!')

                return false
            }
            const channel = JSON.parse(currentChannel)

            if (cartItem && channel) {
                return cartItem.channels.includes(channel.id)
            }
        },
        totalPriceWithQuantity(cartItem) {
            if (this.isCartItemAvailable(cartItem) && !this.cartItemsLoading) {
                return cartItem.price ? (cartItem.quantity > 1 ? cartItem.price * cartItem.quantity : cartItem.price) : 0
            } else {
                return 0
            }
        },
        addDeliveryMethod(index) {
            this.selectedShippingMethodIndex = index
        },
        async updateCountryCode(selectedCountryCode) {
            await this.fetchShippingMethods(selectedCountryCode)
        },
        async fetchShippingMethods(countryCode) {
            if (null === countryCode) {
                this.shippingMethods = []
                this.shippingMethodError = true
            } else {
                try {
                    const response = await this.makeAxiosRequest('GET', `${BASE_URL}/bff/shipping-methods?country=${countryCode}`, this.userApiKey)
                    this.shippingMethods = response.data

                    this.shippingMethodError = !this.shippingMethods.length
                } catch (error) {
                    console.error('Error fetching shipping methods:', error)
                }
            }
        },
        scrollToShippingAddressTitle() {
            const shippingAddressTitle = document.getElementById('shipping-address-title')

            if (shippingAddressTitle) {
                shippingAddressTitle.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        },
        submitData() {
            const channelSlug = JSON.parse(localStorage.getItem('currentChannel')).slug
            const currentChannelId = JSON.parse(localStorage.getItem('currentChannel')).id

            const items = this.cartItems
                .filter(cartItem => cartItem.channels.includes(currentChannelId))
                .map(cartItem => {
                    const item = {
                        sku: cartItem.sku,
                        qty: cartItem.quantity ? cartItem.quantity : 1,
                        name: cartItem.name
                    }

                    if (cartItem.customizationKey) {
                        item.configuration = {
                            customization_key: cartItem.customizationKey
                        }
                    }

                    return item
                })

            const addressFields = {
                email: "",
                phone: "",
                name: "",
                country_id: "",
                city: "",
                postalcode: "",
                address: "",
            }

            this.addressFields.forEach(addressField => {
                if (addressField.name === "firstname" || addressField.name === "lastname") {
                    addressFields.name += addressField.value + " "
                } else if (addressField.name === "country") {
                    addressFields.country_id = addressField.value
                } else {
                    addressFields[addressField.name] = addressField.value
                }
            })

            addressFields.name = addressFields.name.trim()

            const billPayer = {
                email: "omer@framestory.io",
                phone: "+972 54-445-1266",
                firstname: "Omer",
                lastname: "Bachar",
                company_name: "Frame Story LTD",
                tax_nr: "DE358768134",
                is_organization: true,
                address: {
                    country_id: "IL",
                    city: "Hod-HaSharon",
                    postalcode: "4526447",
                    address: "HaTechelet 17 St."
                }
            }

            const data = {
                channel: channelSlug,
                items: items,
                language: "en",
                billpayer: billPayer,
                shipping_address: addressFields,
                shipping_method: this.shippingMethods[this.selectedShippingMethodIndex].id,
                payment_method: PAYMENT_METHOD_ID,
            }

            const formData = new FormData()

            const flattenObject = (obj, prefix = '') => {
                return Object.keys(obj).reduce((acc, key) => {
                    const nestedKey = prefix ? `${prefix}[${key}]` : key
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        Object.assign(acc, flattenObject(obj[key], nestedKey))
                    } else {
                        acc[nestedKey] = obj[key]
                    }
                    return acc
                }, {})
            }

            const flattenedData = flattenObject(data)

            Object.entries(flattenedData).forEach(([key, value]) => {
                formData.append(key, value)
            })

            if (!addressFields.country_id || !addressFields.city || !addressFields.address || !addressFields.postalcode) {
                this.formInvalid = true
                this.scrollToShippingAddressTitle()
            } else if (addressFields.postalcode.length < 4) {
                this.postalCodeLengthInvalid = true
                this.scrollToShippingAddressTitle()
            } else {
                this.makeAxiosRequest('POST', BASE_URL + '/bff/order', this.userApiKey, formData)
                    .then(response => {
                        this.emitter.emit('sent-form-successfully', response.data.order.number)
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status !== 422 || error.response.status !== 500) {
                                this.errorMessage = "An unexpected error occurred. Please try again later"
                            }

                            if (error.response.status === 422) {
                                this.errorMessage = error.response.data.message

                                // Initialize errorMessages array to store error messages for each item
                                const cartItemErrorMessages = new Array(this.cartItems.length).fill([]);

                                for (const key in error.response.data.errors) {
                                    if (Object.prototype.hasOwnProperty.call(error.response.data.errors, key)) {
                                        const errorMessagesForItem = error.response.data.errors[key];
                                        const itemIndex = key.split('.')[1];

                                        if (errorMessagesForItem && itemIndex !== undefined) {
                                            // Concatenate error messages for the corresponding item
                                            cartItemErrorMessages[itemIndex] = cartItemErrorMessages[itemIndex].concat(errorMessagesForItem);
                                        }
                                    }
                                }

                                // Set errorMessages in the data to be used in the template
                                this.cartItemErrorMessages = cartItemErrorMessages;
                            }

                            if (error.response.status === 500) {
                                this.errorMessage = "Internal server error"
                            }
                        } else if (error.request) {
                            this.errorMessage = "No response received. Check your internet connection or the server is not responding"
                        } else {
                            this.errorMessage = "An unexpected error has happened" + error.message
                        }
                    })
            }
        }
    }
}
</script>
