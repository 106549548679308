import { createApp } from 'vue'
import mitt from 'mitt'
import axios from 'axios'
import App from './App.vue'

const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.config.globalProperties.makeAxiosRequest = async function (requestMethod, url, userApiKey, data) {
  try {
    return await axios({
      method: requestMethod,
      url: url,
      headers: {
        'X-Api-Key': userApiKey,
        'Content-Type': requestMethod === 'GET' ? 'application/json' : 'multipart/form-data',
      },
      data: data,
    })
      .catch(error => {
        console.error('Error:', error)
        throw error
      })
  } catch (error) {
    console.error('Axios ' + requestMethod + ' request error:', error)
    throw error
  }
}

app.config.globalProperties.totalOrderable = (item) => {
  return null === item.backorder ? 9999 : item.stock + item.backorder
}

app.mount('#app')
