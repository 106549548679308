<template>
    <ul class="msp-main-content-container">
        <li v-for="product in products" :key="product.sku" class="msp-list-content">
            <div class="msp-list-product-container">
                <img :src="getProductImage(product)" :alt="product.name" class="msp-list-product-image">

                <div class="msp-list-product-description">
                    <p class="msp-list-product-name">{{ product.name }}</p>
                </div>
            </div>

            <div class="msp-list-product-info">
                <p class="msp-color-light">Stq.: <span class="msp-color-dark msp-font-default">{{ product.stock }}</span></p>
                <p class="msp-color-light">SKU: <span class="msp-color-dark msp-font-default">{{ product.sku }}</span></p>
                <p class="msp-color-light">Price: <span class="msp-color-dark msp-font-large">{{ product.price }} &euro;</span></p>

                <template v-if="canAddToCart(product)">
                    <button v-if="product.custom_attributes && product.custom_attributes.template_key"
                            @click="openApcLink(product)"
                            v-bind:disabled="!canAddToCart(product)"
                            title="Customize"
                            class="msp-button msp-button-secondary msp-button-right">
                        <img src="/img/picture.svg" alt="Edit">
                    </button>

                    <button v-else
                            @click="addToCart(product)"
                            v-bind:disabled="!canAddToCart(product)"
                            title="Add to cart"
                            class="msp-button msp-button-primary msp-button-right">
                        <img src="/img/cart.svg" alt="Add to cart">
                    </button>
                </template>

                <button v-else
                        title="Out of stock"
                        class="msp-button msp-button-disabled msp-button-right">
                    <img src="/img/ban.svg" alt="Out of stock">
                </button>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        userData: {
            type: Object,
            required: true
        },
        products: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            apc: null,
            defaultItem: null,
            cartItemImage: null,
        }
    },
    created() {
        let query = {}
        location.search.substr(1).split("&").forEach(function(item) {query[item.split("=")[0]] = item.split("=")[1]})

        if (query.data) {
            let b64 = query.data.replace('%3D', '').replace('%3D', '')
            let apcParams = window.atob(b64)
            const apc = new URLSearchParams(apcParams)

            // Find the defaultItem based on a unique identifier
            const uniqueItemId = apc.get('product');
            this.defaultItem = this.products.find(item => item.id === uniqueItemId);

            if (!this.defaultItem) {
                console.error('Default item not found.')
                return
            }

            if (apc.get('previewUrl')) {
                this.cartItemImage = apc.get('previewUrl')
            } else {
                this.defaultItem.images.forEach(img => {
                    this.cartItemImage = img.urls['checkout']
                })
            }

            const product = {
                id: apc.get('product'),
                channels: this.defaultItem.channels,
                sku: this.defaultItem.sku,
                img: this.cartItemImage,
                name: this.defaultItem.name,
                price: this.defaultItem.price,
                stock: this.defaultItem.stock,
                backorder: this.defaultItem.backorder,
                customizationKey: apc ? apc.get('customizationKey') : this.defaultItem.customizationKey
            }

            this.addToCart(product)
        }
    },
    methods: {
        addToCart(product) {
            this.$emit('add-to-cart', product)
            this.emitter.emit('check-product-item-availability')
        },
        openApcLink(product) {
            const apcLink = this.getApcLink(product)
            window.open(apcLink, '_self')
        },
        getApcLink(product) {
            let formData = `product=${product.id}&related_product=&options[5012]=6755&options[5013]=&qty=1&input_marker=1`
            let baseData = encodeURIComponent(
                window.btoa(
                    formData + `&token=rre0jverv0rv88374ht57f&templateKey=${product.custom_attributes.template_key}&storeUrl=${window.location.href}&lang=${'en'}`
                )
            )

            return "https://v5.cnfgrtr.com/simple-configuration?data=" + baseData
        },
        getProductImage(product) {
            if (!product.images || product.images.length === 0) {
                return '/img/noImagePlaceholder.webp';
            }

            const primaryImage = product.images.find(image => image.is_primary);

            return primaryImage ? primaryImage.urls.checkout : product.images[0].urls.checkout;
        },
        canAddToCart(product) {
            let total_orderable = this.totalOrderable(product)

            return total_orderable >= 1
        },
    }
}
</script>
