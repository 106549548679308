<template>
    <div class="msp-card-container">
        <p class="msp-card-title">Delivery</p>

        <p v-if="!shippingMethods.length && !shippingMethodError" class="msp-warning-box">Please select the shipping country for the available shipping methods</p>

        <p v-if="shippingMethodError" class="msp-warning-box">There is no shipping method configured to this country</p>

        <div v-else v-for="(shippingMethod, index) in shippingMethods" :key="shippingMethod.title" @click="addDeliveryMethod(index)" :class="['msp-delivery-container', { 'msp-delivery-container-active' : selectedIndex === index }]">
            <p class="msp-delivery-title">{{ shippingMethod.name }}</p>
            <p class="msp-color-light">{{ shippingMethod.carrier }}</p>
            <p class="msp-color-light">{{ shippingMethod.time }}</p>
            <p class="msp-delivery-price">{{ shippingMethod.shipping_fee.amount }} &euro; <span v-if="shippingMethod.shipping_fee.is_estimate" class="msp-color-light msp-font-small">(Estimated price)</span></p>

            <img v-if="selectedIndex === index" src="/img/check.svg" alt="checkmark" class="msp-delivery-check">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        shippingMethods: {
            type: Array,
            required: true
        },
        shippingMethodError: {
            type: Boolean,
            required: true
        },
        selectedShippingMethodIndex: {
            type: Number,
            required: true
        },
        addDeliveryMethod: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            selectedIndex: this.selectedShippingMethodIndex
        }
    },
    watch: {
        'selectedShippingMethodIndex'() {
            this.selectedIndex = this.selectedShippingMethodIndex
        }
    }
}
</script>
