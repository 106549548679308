<template>
    <div class="msp-card-container">
        <p class="msp-card-title">Shipping Address</p>

        <div class="msp-address-grid">
            <div v-for="addressField in addressFields"
                 :key="addressField.label"
                 :class="[
                    'msp-address-' + addressField.name,
                    {'msp-error' : formInvalid && addressField.required && !addressField.value || (addressField.name === 'postalcode' && postalCodeLength) }
                 ]"
                 class="msp-address-info">
                <label>{{ addressField.label }} <span v-if="addressField.required" class="msp-address-required">*</span></label>

                <select v-if="addressField.name === 'country'" v-model="addressField.value" :name="addressField.name" @change="onCountrySelected">
                    <option v-for="country in addressField.countries" :key="country.code" :value="country.code">{{ country.name }}</option>
                </select>

                <input v-else v-model="addressField.value" :name="addressField.name" :type="addressField.name === 'email' ? 'email' : (addressField.name === 'phone' ? 'tel' : 'text')">

                <p v-if="formInvalid && addressField.required && !addressField.value" class="msp-error-text">
                    The {{ addressField.name }} is required
                </p>

                <p v-if="addressField.name === 'postalcode' && postalCodeLength" class="msp-error-text">
                    The {{ addressField.name }} must be at least 4 characters
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['language-selected', 'country-code-selected'],
    props: {
        addressFields: {
            type: Array,
            required: true
        },
        formInvalid: {
            type: Boolean
        },
        postalCodeLength: {
            type: Boolean
        }
    },
    methods: {
        onCountrySelected() {
            const countryField = this.addressFields.find(field => field.name === 'country')

            if (countryField && countryField.value) {
                this.$emit('country-code-selected', countryField.value)
            }
        }
    }
}
</script>
