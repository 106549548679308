<template>
    <div class="msp">
        <StoreHeader :user-data="userData"></StoreHeader>

        <template v-if="loadingPage">
            <div class="msp-loading-container">
                <p>Loading...</p>
            </div>
        </template>

        <template v-else-if="!userApiKeyIsValid">
            <div class="msp-login-container">
                <label>Insert your API key</label>
                <input v-model="userApiKey" type="text">
                <p v-if="authErrorMessage" class="msp-error-text">The API key is not valid</p>

                <button @click="authUser" class="msp-button msp-button-primary msp-button-full">Login</button>
            </div>
        </template>

        <template v-else>
            <div v-if="orderSent" class="msp-success-container">
                <h1>The order was sent successfully</h1>
                <p>Order number: {{ orderNumber }}</p>

                <button @click="reload" class="msp-button msp-button-primary msp-button-large">Create a new order</button>
            </div>

            <div v-else class="msp-wrapper msp-container-large">
                <main>
                    <div class="msp-title-container msp-combined-search-wrapper">
                        <h3>Product list</h3>

                        <div class="msp-combined-search-container">
                            <div class="msp-search-container">
                                <input v-model="searchQuery" type="search">
                                <img src="/img/search.svg" alt="search" class="msp-icon-search">
                            </div>

                            <button @click="filerDropdownIsOpen = !filerDropdownIsOpen" class="msp-button-filter">
                                <span class="msp-filter-title">Categories</span>
                                <img src="/img/filter.svg" alt="filter" class="msp-icon-filter">
                            </button>

                            <ul v-if="filerDropdownIsOpen" class="msp-filter-dropdown">
                                <li>
                                    <button @click="[selectedCategory = '', filerDropdownIsOpen = false]" :class="{ 'msp-filter-selected-category': selectedCategory === '' }" class="msp-filter-category-button">
                                        All
                                    </button>
                                </li>
                                <li v-for="uniqueTaxonName in uniqueTaxonNames" :key="uniqueTaxonName">
                                    <button @click="selectCategory(uniqueTaxonName)" :class="{ 'msp-filter-selected-category': uniqueTaxonName === selectedCategory }" class="msp-filter-category-button">
                                        {{ uniqueTaxonName }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="loadingProducts">
                        <p>Fetching products...</p>
                    </div>

                    <ProductList v-else :user-data="userData" :products="searchedItems" @add-to-cart="addToCart"></ProductList>
                </main>

                <aside v-if="cartItems.length">
                    <div class="msp-title-container">
                        <h3>Summary</h3>
                    </div>

                    <SideCart :user-data="userData" :cart-items-loading="loadingProducts" :cart-items="cartItems" :products="products" @form-success="orderSent" :user-api-key="userApiKey"></SideCart>
                </aside>
            </div>
        </template>
    </div>
</template>

<script>
import ProductList from "../components/ProductList.vue"
import SideCart from "../components/SideCart.vue"
import StoreHeader from "../components/StoreHeader.vue"
import {BASE_URL} from "../../config"

export default {
    components: { StoreHeader, SideCart, ProductList },
    data() {
        return {
            loadingPage: true,
            loadingProducts: true,
            userApiKeyIsValid: false,
            userData: null,
            userApiKey: null,
            authErrorMessage: false,
            searchQuery: '',
            selectedCategory: '',
            selectedChannel: null,
            filerDropdownIsOpen: false,
            cartItems: [],
            products: [],
            orderSent: false,
            orderNumber: '',
            isInitialLoad: true,
        }
    },
    created() {
        this.userApiKey = localStorage.getItem('userApiKey')
        if (this.userApiKey) {
            this.validateApiKey()
        } else {
            this.loadingPage = false
        }

        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || []
        this.cartItems = storedCartItems.filter(item =>
            !this.cartItems.some(cartItem =>
                item.customizationKey && cartItem.customizationKey && cartItem.customizationKey === item.customizationKey
            )
        )

        this.emitter.on("remove-cart-item", (sku) => {
            this.cartItems = this.cartItems.filter(item => item.sku !== sku)
            this.updateLocalStorage()
            this.replaceQueryParam()
        })

        this.emitter.on("sent-form-successfully", (orderNumber) => {
            this.orderSent = true
            this.orderNumber = orderNumber
            this.cartItems = []
            this.updateLocalStorage()
            this.replaceQueryParam()
        })

        this.emitter.on("filter-by-channel", this.handleChannelChange)
    },
    computed: {
        searchedItems() {
            const query = this.searchQuery.toLowerCase()

            return this.products.filter(product => {
                const matchesQuery = product.name.toLowerCase().includes(query) || product.sku.includes(query)

                const matchesCategory = !this.selectedCategory ||
                    product.taxons.some(taxon => taxon.name.toLowerCase() === this.selectedCategory.toLowerCase())

                const matchesChannel = !this.selectedChannel ||
                    (product.channels && product.channels.some(channel => channel === this.selectedChannel))

                return matchesQuery && matchesCategory && matchesChannel
            })
        },
        // Remove duplicated taxon names
        uniqueTaxonNames() {
            const uniqueNames = []

            this.products.forEach(item => {
                item.taxons.forEach(taxon => {
                    const taxonName = taxon.name.toLowerCase()

                    if (!uniqueNames.includes(taxonName)) {
                        uniqueNames.push(taxonName)
                    }
                })
            })

            return uniqueNames
        }
    },
    methods: {
        authUser() {
            this.loadingPage = true
            this.validateApiKey()
        },
        validateApiKey() {
            this.makeAxiosRequest('GET', BASE_URL + '/bff/me?include=channels', this.userApiKey)
                .then(response => {
                    if (response.status === 200) {
                        this.handleValidApiKey()
                        this.userData = response.data
                    } else {
                        this.authErrorMessage = true
                    }
                })
                .catch(() => {
                    this.authErrorMessage = true
                })
                .finally(() => {
                    this.loadingPage = false
                })
        },
        handleValidApiKey() {
            this.userApiKeyIsValid = true
            localStorage.setItem('userApiKey', this.userApiKey)

            this.$nextTick(() => {
                this.fetchProductItems()
            })
        },
        addToCart(product) {
            // If an item with the same customizationKey already exists, update the image of the existing item
            const existingItem = this.cartItems.find(item => item.sku === product.sku)

            if (this.cartItems.length <= 0 || !existingItem || existingItem.customizationKey !== product.customizationKey) {
                product.quantity = 1
                this.cartItems.push(product)
            } else if (existingItem.customizationKey && existingItem.customizationKey === product.customizationKey) {
                existingItem.img = product.img
            } else {
                if (existingItem.total_orderable < existingItem.quantity+1) {
                    alert('This quantity is not available')

                    return
                }

                existingItem.quantity++
            }

            this.updateLocalStorage()

            if (this.isInitialLoad) {
                if (this.userData.channels.length < 1) {
                    alert('The user is not associated with any channels!')
                    localStorage.removeItem('currentChannel')
                } else {
                    localStorage.setItem('currentChannel', JSON.stringify(this.userData.channels[0]))
                }
            }
        },
        updateLocalStorage() {
            localStorage.setItem('cartItems', JSON.stringify(this.cartItems))
        },
        replaceQueryParam() {
            const urlWithoutCart = this.removeQueryParam(window.location.href)
            window.history.replaceState({}, '', urlWithoutCart)
        },
        removeQueryParam(url) {
            const urlObj = new URL(url)
            return urlObj.href = urlObj.origin
        },
        selectCategory(category) {
            this.selectedCategory = category
            this.filerDropdownIsOpen = false
        },
        handleChannelChange(channel) {
            this.isInitialLoad = false
            this.selectedChannel = channel.id
            localStorage.setItem('currentChannel', JSON.stringify(channel))

            this.fetchProductItems()
        },
        async fetchProductItems() {
            this.loadingProducts = true
            const storedChannel = localStorage.getItem('currentChannel');
            if (undefined === storedChannel || 'undefined' === storedChannel) {
                console.error('There is no channel selected')
                this.loadingProducts = false

                return
            }
            const currentChannel = JSON.parse(localStorage.getItem('currentChannel'))
            const endpoint = BASE_URL + '/bff/products?include=images,channels&channel=' + (currentChannel ? currentChannel.slug : this.userData.channels[0].slug)

            try {
                const response = await this.makeAxiosRequest('GET', endpoint, this.userApiKey)
                this.products = response.data.flatMap(item => {
                    if (item.product_type === 'product') {
                        return [item]
                    } else if (item.product_type === 'master_product') {
                        const masterProductChannels = item.channels || []

                        // Append master_product channels to its variants
                        const itemsWithMasterProductChannels = item.variants.map(variant => {
                            const variantWithChannels = { ...variant }

                            variantWithChannels.channels = [...(variant.channels || []), ...masterProductChannels]

                            return variantWithChannels
                        })

                        // Return the master_product variants with appended channels
                        return [...itemsWithMasterProductChannels]
                    }
                    return []
                })
            } catch (error) {
                console.error('Error fetching product items:', error)
            } finally {
                this.loadingProducts = false
            }
        },
        reload() {
            window.location.reload()
        }
    }
}
</script>
