<template>
    <MasterPics></MasterPics>
</template>

<script>
import MasterPics from "./components/MasterPics.vue"

export default {
    name: 'App',
    components: {
        MasterPics
    },
    mounted() {
        document.body.classList.add('msp-body')
    },
}
</script>

<style scoped>
@import './assets/css/masterpics.css';
</style>
