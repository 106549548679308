<template>
    <div class="msp-quantity-container">
        <button @click="decrease" class="msp-quantity-button">-</button>

        <input type="text" :value="quantity" maxlength="3" class="msp-quantity-input">

        <button @click="increase" class="msp-quantity-button">+</button>
    </div>
</template>

<script>
export default {
    props: {
        quantity: {
            type: Number,
            required: true,
            default: 1
        }
    },
    methods: {
        increase() {
            this.$emit('update:quantity', this.quantity + 1)
        },
        decrease() {
            if (this.quantity > 1) {
                this.$emit('update:quantity', this.quantity - 1)
            }
        }
    }
}
</script>
